



















import { Getter, Action } from 'vuex-class';
import { Component, Watch, Vue } from 'vue-property-decorator';

@Component({
    components: {
        ElButton: () => import('@/components/elements/Button.vue'),
        ProductSample: () => import('@/components/product/ProductSample.vue'),
    },
})
export default class OrderSummary extends Vue {
    @Getter('company/cart') cart!: Cart;
    @Getter('company/viewing') company!: Company;
    @Getter('user/user') user!: Auth;
    @Getter('pageLoading') pageLoading!: boolean;

    @Action('company/readCart') readCart!: (payload?: { id: number }) => Promise<Cart>;
    @Action('company/updateCart') updateCart!: (payload?: CartPutPayload) => Promise<any>;
    @Action('product/read') readProduct!: (payload: { slug: string }) => void;

    i18n: string = 'order.summary';

    polling: boolean = false;
    ready: boolean = false;

    startPolling(companyId: number) {
        this.polling = true;

        const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
        const poll = (promiseFn: any, time: number): void => {
            if (this.polling) {
                promiseFn().then(() => {
                    this.ready = true;
                    sleep(time).then(() => poll(promiseFn, time));
                });
            }
        };

        poll(() => this.readCart({ id: companyId! }), 5000);
    }

    destroyed() {
        this.polling = false;
    }

    edit(product: Product) {
        if (product && product.slug) {
            this.readProduct({ slug: product.slug });
        }
    }

    @Watch('pageLoading', { immediate: true })
    pageLoaded(loading: boolean) {
        const companyId = this.company != null ? this.company.id : this.user.company_id != null ? this.user.company_id : null;

        if (!loading && companyId) {
            this.readCart({ id: companyId! })
                .then((cart: Cart) => {
                    // update the cart to get new media etc.
                    if (cart.products && cart.products.length) {
                        const updatePayload = {
                            company_id: companyId,
                            products: cart.products.map((p) => {
                                return {
                                    variant_id: p.variant_id,
                                    quantity: p.quantity,
                                    additional_info: p.additional_info,
                                };
                            }),
                        };

                        this.updateCart(updatePayload);
                    }
                })
                .finally(() => this.startPolling(companyId));
        }
    }
}
